<template>
	<div>
		<div class="banner" :style="'background-image:' + `url(${require('@/assets/img/banner-bg.jpg')})`">
			<div class="ba_title">
				<span>新闻资讯</span>
			</div>
			<div class="ba_text">
				格物思 ● 全球电子智能硬件智造平台
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			
		},
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 200px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 140px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 80px 30px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size: 30px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 140px 30px 80px 30px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size:21px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner{
			position: relative;
			text-align: center;
			background-color: #171c1f;
			padding: 140px 30px 80px 30px;
			
			.ba_title{
				display: flex;
				text-align: center;
				font-size:21px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
			}
			
			.ba_text{
				display: flex;
				text-align: center;
				font-size: 16px;
				color: #ffffff;
				width: 100%;
				justify-content: center;
				padding-top: 10px;
			}
		}
	}
	
</style>